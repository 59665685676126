<mat-icon
  *ngIf="task?.issueWasUpdated"
  color="accent"
  style="margin-right: 8px"
  >update
</mat-icon>
<mat-icon
  *ngIf="!task?.issueWasUpdated"
  style="margin-right: 8px"
  svgIcon="open_project"
>
</mat-icon>
<span class="tab-label-text">OpenProject</span>
