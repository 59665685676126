<form [formGroup]="form">
  <formly-form
    (modelChange)="updateCfg($event)"
    [fields]="fields"
    [form]="form"
    [model]="config"
    [options]="options"
  >
  </formly-form>
</form>

<!--<pre><code>{{config|json}}</code></pre>-->
