<mat-form-field>
  <mat-label>{{T.F.TASK.SELECT_OR_CREATE|translate}}</mat-label>
  <input
    [formControl]="taskSelectCtrl"
    [matAutocomplete]="auto"
    cdkFocusInitial
    autofocus="autofocus"
    matInput
    required
  />
  <mat-icon
    *ngIf="!isCreate"
    matSuffix=""
    >track_changes
  </mat-icon>
  <mat-icon
    *ngIf="isCreate"
    matSuffix=""
    >add
  </mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
    [displayWith]="displayWith"
  >
    <mat-option
      *ngFor="let task of filteredTasks; trackBy:trackById"
      [value]="task"
    >
      <mat-icon
        *ngIf="task.issueType"
        [svgIcon]="task.issueType|issueIcon"
      ></mat-icon>
      <mat-icon *ngIf="!task.issueType && !projectMap[task.projectId]"
        >library_books
      </mat-icon>
      <tag
        *ngIf="projectMap[task.projectId]"
        [tag]="projectMap[task.projectId]"
      ></tag>
      <span [innerHTML]="task.title"></span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
