import { Subscriber } from '../Subscriber';
export function dematerialize() {
  return function dematerializeOperatorFunction(source) {
    return source.lift(new DeMaterializeOperator());
  };
}
class DeMaterializeOperator {
  call(subscriber, source) {
    return source.subscribe(new DeMaterializeSubscriber(subscriber));
  }
}
class DeMaterializeSubscriber extends Subscriber {
  constructor(destination) {
    super(destination);
  }
  _next(value) {
    value.observe(this.destination);
  }
}
