import { SimpleOuterSubscriber, SimpleInnerSubscriber, innerSubscribe } from '../innerSubscribe';
export function skipUntil(notifier) {
  return source => source.lift(new SkipUntilOperator(notifier));
}
class SkipUntilOperator {
  constructor(notifier) {
    this.notifier = notifier;
  }
  call(destination, source) {
    return source.subscribe(new SkipUntilSubscriber(destination, this.notifier));
  }
}
class SkipUntilSubscriber extends SimpleOuterSubscriber {
  constructor(destination, notifier) {
    super(destination);
    this.hasValue = false;
    const innerSubscriber = new SimpleInnerSubscriber(this);
    this.add(innerSubscriber);
    this.innerSubscription = innerSubscriber;
    const innerSubscription = innerSubscribe(notifier, innerSubscriber);
    if (innerSubscription !== innerSubscriber) {
      this.add(innerSubscription);
      this.innerSubscription = innerSubscription;
    }
  }
  _next(value) {
    if (this.hasValue) {
      super._next(value);
    }
  }
  notifyNext() {
    this.hasValue = true;
    if (this.innerSubscription) {
      this.innerSubscription.unsubscribe();
    }
  }
  notifyComplete() {}
}
