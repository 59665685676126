<div
  *ngIf="note"
  [class.isFocused]="isFocus"
  [class.isImg]="note.imgUrl"
  class="note"
>
  <img
    *ngIf="note.imgUrl"
    [enlargeImg]="note.imgUrl"
    [src]="note.imgUrl"
    (longPress)="editFullscreen($event)"
    class="note-img"
  />

  <div
    *ngIf="!note.imgUrl"
    class="content"
  >
    <div
      (click)="editFullscreen($event)"
      markdown
      [data]="isLongNote ? shortenedNote : note.content"
      class="markdown-preview markdown"
    ></div>
  </div>

  <div
    class="bottom-controls handle-drag"
    [class.isOver]="note.imgUrl"
  >
    <button
      mat-icon-button
      (click)="togglePinToToday()"
      *ngIf="note.projectId"
    >
      <mat-icon *ngIf="!note.isPinnedToToday">wb_sunny</mat-icon>
      <mat-icon
        *ngIf="note.isPinnedToToday"
        svgIcon="remove_today"
      ></mat-icon>
    </button>

    <tag
      *ngIf="projectTag$|async as projectTag"
      [tag]="projectTag"
    ></tag>

    <!--    <button mat-icon-button>-->
    <!--      <mat-icon class="drag-handle-ico" svgIcon="drag_handle"></mat-icon>-->
    <!--    </button>-->

    <button
      [matMenuTriggerFor]="menu"
      mat-icon-button
    >
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button
      [matMenuTriggerFor]="projectMenu"
      mat-menu-item
    >
      <mat-icon>forward</mat-icon>
      {{(note.projectId ? T.F.TASK.CMP.MOVE_TO_OTHER_PROJECT :
      T.F.TASK.CMP.ADD_TO_PROJECT)|translate}}
    </button>

    <button
      (click)="toggleLock()"
      *ngIf="!note.imgUrl"
      mat-menu-item
    >
      <mat-icon *ngIf="note.isLock">lock</mat-icon>
      <mat-icon *ngIf="!note.isLock">lock_open</mat-icon>

      {{note.isLock ? (T.F.NOTE.NOTE_CMP.ENABLE_PARSE|translate) :
      (T.F.NOTE.NOTE_CMP.DISABLE_PARSE|translate)}}
    </button>

    <button
      (click)="removeNote()"
      mat-menu-item
    >
      <mat-icon style="color: #e15d63">delete_forever</mat-icon>
      {{T.G.DELETE|translate}}
    </button>

    <mat-menu #projectMenu="matMenu">
      <ng-template matMenuContent>
        <button
          (click)="moveNoteToProject(project.id)"
          *ngFor="let project of (moveToProjectList$|async); trackBy:trackByProjectId;"
          mat-menu-item
        >
          {{project.title}}
        </button>
      </ng-template>
    </mat-menu>
  </ng-template>
</mat-menu>
