<div class="pyro">
  <div class="pyro-before"></div>
  <div class="pyro-after"></div>
</div>

<h1>{{T.F.FOCUS_MODE.CONGRATS|translate}}</h1>

<div class="msg">
  {{T.F.FOCUS_MODE.WORKED_FOR|translate}}
  <em>{{lastSessionDuration$|async|msToString}}</em> {{T.F.FOCUS_MODE.ON|translate}}
  "<em>{{taskTitle$|async}}</em>"
</div>

<div class="btn-wrapper">
  <button
    mat-stroked-button
    color="primary"
    (click)="closeFocusOverlay()"
  >
    {{T.F.FOCUS_MODE.BACK_TO_PLANNING|translate}}
  </button>

  <button
    *ngIf="!(currentTask$|async)"
    mat-raised-button
    color="primary"
    (click)="startNextFocusSession()"
  >
    {{T.F.FOCUS_MODE.START_NEXT_FOCUS_SESSION|translate}}
  </button>

  <button
    *ngIf="(currentTask$|async)"
    mat-raised-button
    color="primary"
    (click)="continueWithFocusSession()"
  >
    {{T.F.FOCUS_MODE.CONTINUE_FOCUS_SESSION|translate}}
  </button>
</div>
