<svg
  viewBox="0 0 36 36"
  class="svg"
>
  <path
    class="circle-bg"
    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
  />
  <path
    #progressCircle
    class="progress-circle"
    stroke-dasharray="0, 100"
    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
  />
</svg>
