<div
  (click)="editTaskRepeatCfg()"
  matTooltip="{{ T.F.PLANNER.EDIT_REPEATED_TASK|translate: {taskName: repeatCfg().title} }}"
>
  <mat-icon svgIcon="repeat"></mat-icon>
  <div class="title">
    {{ repeatCfg().title }}
    <!--   {{event.start|date:'HH:mm'}} – {{(event.start + event.duration)|date:'HH:mm'}}-->
  </div>
  <div class="planner-time-remaining-shared">
    @if (overWriteTimeEstimate() > 0) { {{ overWriteTimeEstimate()|msToString }} <br />
    <span class="original-time-remaining"
      >({{ repeatCfg().defaultEstimate|msToString }})</span
    >
    <!--- -->
    } @else { {{ repeatCfg().defaultEstimate|msToString }} }
  </div>
</div>
