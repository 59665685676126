<ng-container *ngIf="task?.issueType===JIRA_TYPE">
  <jira-issue-content
    [issue]="issueData"
    [task]="task"
  ></jira-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===GITHUB_TYPE">
  <github-issue-content
    [issue]="issueData"
    [task]="task"
  ></github-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===REDMINE_TYPE">
  <redmine-issue-content
    [issue]="issueData"
    [task]="task"
  ></redmine-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===GITLAB_TYPE">
  <gitlab-issue-content
    [issue]="issueData"
    [task]="task"
  ></gitlab-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===CALDAV_TYPE">
  <caldav-issue-content
    [issue]="issueData"
    [task]="task"
  ></caldav-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===OPEN_PROJECT_TYPE">
  <open-project-issue-content
    [issue]="issueData"
    [task]="task"
  ></open-project-issue-content>
</ng-container>

<ng-container *ngIf="task?.issueType===GITEA_TYPE">
  <gitea-issue-content
    [issue]="issueData"
    [task]="task"
  ></gitea-issue-content>
</ng-container>
