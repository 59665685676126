<header>
  <button
    #buttonEl
    (click)="addNote()"
    id="add-note-btn"
    mat-button
  >
    <mat-icon>add</mat-icon>
    {{T.F.NOTE.NOTES_CMP.ADD_BTN|translate}}
  </button>
</header>

<div
  *ngIf="isDragOver"
  @fade
  class="bgc-accent drag-over-msg"
>
  <mat-icon>add</mat-icon>
  {{T.F.NOTE.NOTES_CMP.DROP_TO_ADD|translate}}
</div>

<div
  [@standardList]="(workContextService.notes$|async)?.length"
  [dragulaModel]="(workContextService.notes$|async)"
  class="notes"
  dragula="NOTES"
>
  <ng-container *ngIf="(workContextService.notes$|async) as notes">
    <note
      *ngFor="let note of notes; trackBy:trackById; let i = index;"
      [isFocus]="isElementWasAdded && i===0"
      [note]="note"
    ></note>

    <div
      class="no-notes-info"
      *ngIf="notes.length ===0"
    >
      {{T.F.NOTE.NOTES_CMP.NO_NOTES|translate}}
    </div>
  </ng-container>
</div>
