<h1 mat-dialog-title>
  <mat-icon
    class="dialog-header-icon"
    svgIcon="jira"
  ></mat-icon>
  <span>{{T.F.JIRA.DIALOG_INITIAL.TITLE|translate}}</span>
</h1>
<mat-dialog-content>
  <jira-cfg-stepper
    (saveCfg)="saveJiraCfg($event)"
    [cfg]="jiraCfg"
  ></jira-cfg-stepper>
</mat-dialog-content>
