import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function expand(project, concurrent = Number.POSITIVE_INFINITY, scheduler) {
  concurrent = (concurrent || 0) < 1 ? Number.POSITIVE_INFINITY : concurrent;
  return source => source.lift(new ExpandOperator(project, concurrent, scheduler));
}
export class ExpandOperator {
  constructor(project, concurrent, scheduler) {
    this.project = project;
    this.concurrent = concurrent;
    this.scheduler = scheduler;
  }
  call(subscriber, source) {
    return source.subscribe(new ExpandSubscriber(subscriber, this.project, this.concurrent, this.scheduler));
  }
}
export class ExpandSubscriber extends SimpleOuterSubscriber {
  constructor(destination, project, concurrent, scheduler) {
    super(destination);
    this.project = project;
    this.concurrent = concurrent;
    this.scheduler = scheduler;
    this.index = 0;
    this.active = 0;
    this.hasCompleted = false;
    if (concurrent < Number.POSITIVE_INFINITY) {
      this.buffer = [];
    }
  }
  static dispatch(arg) {
    const {
      subscriber,
      result,
      value,
      index
    } = arg;
    subscriber.subscribeToProjection(result, value, index);
  }
  _next(value) {
    const destination = this.destination;
    if (destination.closed) {
      this._complete();
      return;
    }
    const index = this.index++;
    if (this.active < this.concurrent) {
      destination.next(value);
      try {
        const {
          project
        } = this;
        const result = project(value, index);
        if (!this.scheduler) {
          this.subscribeToProjection(result, value, index);
        } else {
          const state = {
            subscriber: this,
            result,
            value,
            index
          };
          const destination = this.destination;
          destination.add(this.scheduler.schedule(ExpandSubscriber.dispatch, 0, state));
        }
      } catch (e) {
        destination.error(e);
      }
    } else {
      this.buffer.push(value);
    }
  }
  subscribeToProjection(result, value, index) {
    this.active++;
    const destination = this.destination;
    destination.add(innerSubscribe(result, new SimpleInnerSubscriber(this)));
  }
  _complete() {
    this.hasCompleted = true;
    if (this.hasCompleted && this.active === 0) {
      this.destination.complete();
    }
    this.unsubscribe();
  }
  notifyNext(innerValue) {
    this._next(innerValue);
  }
  notifyComplete() {
    const buffer = this.buffer;
    this.active--;
    if (buffer && buffer.length > 0) {
      this._next(buffer.shift());
    }
    if (this.hasCompleted && this.active === 0) {
      this.destination.complete();
    }
  }
}
