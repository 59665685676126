<div
  class="dialog-content"
  mat-dialog-content
>
  <p>{{T.F.TIME_TRACKING.D_IDLE.IDLE_FOR|translate}}</p>
  <div class="time">{{idleTime$|async|msToString:true}}</div>

  <div class="track-to-items">
    <div
      class="track-to-item"
      *ngFor="let item of trackItems"
    >
      <div class="first-block">
        <mat-button-toggle-group [(ngModel)]="item.type">
          <mat-button-toggle value="BREAK">
            <mat-icon>free_breakfast</mat-icon>
          </mat-button-toggle>
          <!--          <mat-button-toggle value="TASK_AND_BREAK">-->
          <!--            <mat-icon>track_changes</mat-icon>-->
          <!--            +-->
          <!--            <mat-icon>free_breakfast</mat-icon>-->
          <!--          </mat-button-toggle>-->
          <mat-button-toggle value="TASK">
            <mat-icon>track_changes</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <select-task
          [initialTask]="item.task"
          (taskChange)="onTaskChange(item, $event)"
          [class.isDisabled]="item.type==='BREAK'"
        >
        </select-task>

        <section class="simple-counter-section">
          <button
            mat-mini-fab
            class="simple-counter"
            type="button"
            *ngFor="let simpleCounterBtn of item.simpleCounterToggleBtns"
            (click)="simpleCounterBtn.isTrackTo=!simpleCounterBtn.isTrackTo"
            [matTooltip]="(simpleCounterBtn.isTrackTo ? T.F.TIME_TRACKING.D_IDLE.SIMPLE_COUNTER_TOOLTIP_DISABLE : T.F.TIME_TRACKING.D_IDLE.SIMPLE_COUNTER_TOOLTIP)|translate:{ title: simpleCounterBtn.title }"
            [color]="simpleCounterBtn.isTrackTo ? 'primary' : ''"
          >
            <mat-icon>{{simpleCounterBtn.icon}}</mat-icon>
          </button>
        </section>
      </div>

      <input-duration-slider
        [(model)]="item.time"
        [label]="T.F.TASK.D_TIME.TIME_SPENT|translate"
      >
      </input-duration-slider>

      <div>
        <button
          mat-icon-button
          [color]="'warn'"
          (click)="removeTrackItem(item)"
          [disabled]="trackItems.length <= 1"
        >
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>

    <div style="text-align: center; margin-top: 8px">
      <button
        mat-button
        (click)="addTrackItem()"
      >
        <mat-icon>add</mat-icon>
        {{T.F.TIME_TRACKING.D_IDLE.ADD_ENTRY|translate}}
      </button>
    </div>
  </div>
</div>

<div
  align="end"
  mat-dialog-actions
>
  <button
    (click)="cancel()"
    color="primary"
    mat-button
    type="button"
  >
    {{T.G.CANCEL|translate}}
  </button>

  <button
    (click)="save()"
    color="primary"
    mat-stroked-button
    type="submit"
  >
    <mat-icon>save</mat-icon>
    {{T.G.SAVE|translate}}
  </button>
</div>
